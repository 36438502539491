// Document.js
import React, { useEffect, useRef, useState } from "react";
import Delete from "./Delete";
import { countWords } from "../../libs/utility";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Img, useMediaQuery } from "@chakra-ui/react";
import deleteOutline from "../../assets/Dashboard/Main/trashOutline.svg";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MdDeleteOutline } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const Document = ({
  id,
  title,
  text,
  createdAt,
  deleteDocument,
  fetchDocuments,
  suggestionCount,
  index,
}) => {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 800px)");

  const [show, setShow] = useState(false);

  const deleteRef = useRef();
  const docRef = useRef();

  const date = new Date(createdAt);
  const thatDate = new Date(date).getTime();
  const nowDate = new Date().getTime();
  const m = moment(thatDate);
  const theme = useTheme();
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (deleteRef.current && deleteRef.current.contains(e.target)) {
        setShow(true);
      } else if (docRef.current && docRef.current.contains(e.target)) {
        navigate(`/editor/${id}`);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id, navigate]);

  const stripHtml = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  return (
    <>
      {/* <div
      style={{ hyphens: "auto"  }}
      className={`d-flex flex-col cursor-pointer mr-0 p-3 w-[${isMobile ? "100%" : "14rem"}] h-[12rem] text-center rounded-sm shadow-md relative hyphens-auto`}
    >
      <div ref={docRef} key={id} className="w-[100%] h-[100%] bg-white">
        <div
          className="absolute rounded-tr-lg top-0 right-0
                        px-2.5 py-0.5
                        bg-[#3b82f6] 
                        text-[#FFFFFF] text-sm font-[400]"
        >
          {suggestionCount + 1 ?? 0}
        </div>
        <div className="mb-2 text-[#000000] text-md font-[600] text-left">
          {title}
        </div>
        <div className="text-[#767790] text-[0.9rem] text-left max-h-[20px] w-[100%]">
          {text != null && text?.length > 0 ? text?.substr(0, 50) + "..." : ""}
        </div>
        <div className="mt-20 absolute left-4 text-[#949494] text-[0.8rem] font-[600]">
          {countWords(text)} words
        </div>
        <div className="absolute bottom-4 text-[#767790] text-[0.6rem]">
          Recent :{" "}
          {(nowDate - thatDate) / 1000 > 86400 ? m.format("L") : m.fromNow()}
        </div>
        <button
          // ref={deleteRef}
          className="absolute bottom-4 right-1"
          style={{backgroundColor:"red"}}
          onClick={() => { console.log("hii"); navigate(`/editor/${id}`)}} // Added click event to navigate
        >
          <Img src={deleteOutline}   />
        </button>
      </div>
      <Delete
  
        id={id}
        show={show}
        setShow={setShow}
        deleteDocument={deleteDocument}
        fetchDocuments={fetchDocuments}
      />
    </div> */}

      <Grid item xs={4} sm={6} lg={2} sx={{ mr: 3 }}>
        <div
          style={{
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
            width: "140px",
            height: "173px",
          }}
        >
          <Card
            ref={docRef}
            sx={{
              borderTopLeftRadius: "5px !important",
              borderTopRightRadius: "5px !important",
              borderBottomLeftRadius: "0px !important",
              borderBottomRightRadius: "0px !important",
              width: "140px",
              height: "142px",              
            }}
          >
            <CardContent className="cursor-pointer">
              {/* <Typography sx={{ fontSize: "14px", lineHeight: "13px", color: "#090909", fontWeight: 600, }}>
                {title ? title.charAt(0).toUpperCase() + title.slice(1) : text ?text.split(" ").slice(0, 2).join(" ") : "-" }
              </Typography> */}
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "13px",
                  color: "#090909",
                  fontWeight: 600,
                }}
              >
                {title ? (
                  <Tooltip
                    // placement="top"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -8], // Adjust this for more or less space between the tooltip and the element
                          },
                        },
                      ],
                    }}
                    title={title}
                    arrow
                  >
                    {title.charAt(0).toUpperCase() +
                      title.slice(1, 10) +
                      (title.length > 10 ? "..." : "")}
                  </Tooltip>
                ) : text ? 
                <Tooltip
                    // placement="top"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -8], // Adjust this for more or less space between the tooltip and the element
                          },
                        },
                      ],
                    }}
                    title={(
                      stripHtml(text)
                        .split(" ")
                        .slice(0, 5)
                         + "..."
                    )}
                    arrow
                  >{(
                    stripHtml(text)
                      .split(" ")
                      .slice(0, 2)
                      .join(" ")
                      .slice(0, 10) + "..."
                  )}</Tooltip> : (
                  "-"
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: "13px",
                  color: "#090909",
                  fontWeight: 400,
                  mt: 1,
                  wordBreak: "break-word",
                }}
              >
                {text && text.length > 0 ? (
                  `${stripHtml(text).substr(0, 150)}...`
                ) : (
                  <div style={{ color: "#A1A1A1", fontFamily: "Poppins" }}>
                    No Content
                  </div>
                )}
              </Typography>

              {/* <Typography sx={{ fontSize: "10px", lineHeight: "13px", color: "#090909", fontWeight: 400, mt: 1 }}>
                 {text != null && text?.length > 0 ? text?.substr(0, 22) + "..." : ""}
                 </Typography> */}
            </CardContent>
          </Card>
          <Card
            sx={{
              borderTopLeftRadius: "0px !important",
              borderTopRightRadius: "0px !important",
              borderBottomLeftRadius: "5px !important",
              borderBottomRightRadius: "5px !important",
              borderTop: "0.2px solid #000000",
              paddingLeft: "10%",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#3A3AF4",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                {suggestionCount}
              </Typography>
              <button
                style={{ marginRight: "10px" }}
                ref={deleteRef}
                className=""
                // onClick={() => navigate(`/editor/${id}`)} // Added click event to navigate
              >
                <Tooltip
                  arrow
                  title="Delete"
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -3], // Change 5 to adjust the vertical distance from the icon
                        },
                      },
                    ],
                  }}
                  sx={{ background: "none", m: 0, p: 0, position: "top" }}
                >
                  <IconButton
                    sx={{ background: "none", position: "top", m: 0, p: 0 }}
                  >
                    <MdDeleteOutline
                      className="w-6 h-6 text-[#A1A1A1]  hover:text-[#3A3AF4] icon-link-hover"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </IconButton>
                </Tooltip>
              </button>

              <Delete
                id={id}
                show={show}
                setShow={setShow}
                deleteDocument={deleteDocument}
                fetchDocuments={fetchDocuments}
              />
            </Box>
          </Card>
        </div>
      </Grid>
    </>
  );
};

export default Document;
