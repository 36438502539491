import React, { useEffect, useState } from 'react';
import crossIcon from "../../../assets/Editor/grammarCorrectionCrossIcon.png";
import { replaceWord } from "../../../libs/utility";
import { Img } from '@chakra-ui/react';
import CircularProgress from "@mui/material/CircularProgress";
const ErrorWordPopup = ({
    isOpen,
    error,
    setIsOpen,
    results,
    document,
    setDocument,
    checkGrammar,
    updateDocumentContent,
    setSelectedErrorKey

}) => {
    const [errorWord, setErrorWord] = useState("");
    const [errorContext, setErrorContext] = useState("");
    const [errorCategory, setErrorCategory] = useState("");
    const [errorDescription, setErrorDescription] = useState("");
    const [loadingIndex, setLoadingIndex] = useState(null); // Track loading state for buttons
    const [correctedWord, setCorrectedWord] = useState([])
    const fixWord = async (replacement, offset, length, outerIndex) => {
        if (!document || !replacement) return;
        setLoadingIndex(outerIndex); // Set loading state for the clicked button

        const text = document.text;

        // Update the corrected word in the document
        const correctString = await replaceWord(text, replacement, offset, length);
        setDocument({ ...document, text: correctString });
        await saveAndCheck(correctString);

        setLoadingIndex(null); // Remove loading state after processing
        setIsOpen(false)
    };

    const saveAndCheck = async (correctString) => {
        if (updateDocumentContent) await updateDocumentContent(correctString);
        if (checkGrammar) await checkGrammar();
    };

    const getColorByCategory = (category) => {
        const colors = {
            TYPOS: "orange",
            SPELLING: "purple",
            TYPOGRAPHY: "#0066ff",
            CONFUSED_WORDS: "#FF7F50",
            SEMANTICS: "#ff66b2",
            STYLE: "#996633",
            MORFOLOGIK_RULE_EN_US: "#008080",
            PUNCTUATION: "#FFD700",
            MISC: "blueviolet",
            GRAMMAR: "red",
        };
        return colors[category] || "green";
    };

    useEffect(() => {
        if (error) {
            const text = error.context.text;
            const offset = error.offset;
            const length = error.length;

            // Extract specific error details
            setErrorWord(text.substr(offset, length));
            setErrorCategory(error.rule.category?.id || "Unknown");
            setErrorDescription(error.shortMessage || "No additional description available");

            // Extract contextual text
            const contextStart = Math.max(0, offset - 20);
            const contextEnd = Math.min(text.length, offset + length + 20);
            setErrorContext(text.substring(contextStart, contextEnd));
            const firstTwoElements = error?.replacements?.slice(0, 2);
            console.log(firstTwoElements, "firstTwoElementsfirstTwoElementsfirstTwoElements");
            setCorrectedWord(firstTwoElements)
        }
    }, [error]);

    if (!isOpen || !error) return null;

    return (
        <>
            {isOpen && (
                <div
                    onClick={(e) => e.stopPropagation()
                    }
                    style={{
                        border: `2px solid ${getColorByCategory(error.rule.category?.id)}`,
                    }}
                    className="fixed right-40 top-1/4 transform -translate-y-1/4 z-50 w-[300px] bg-white shadow-lg rounded-lg p-4"
                >
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <Img
                            style={{
                                height: "22px",
                                width: "22px",
                                cursor: "pointer",
                            }}
                            src={crossIcon}
                            onClick={() => {
                                setIsOpen(false);
                                setSelectedErrorKey(null)
                            }}
                        />
                    </div>
                    <div>
                        <h2
                            className="text-[18px] font-bold"
                            style={{ color: getColorByCategory(error.rule.category?.id) }}
                        >
                            {errorCategory} Error
                        </h2>
                        <div>
                            <p className="font-semibold text-[16px]">Description</p>
                            <p className="text-gray-700 text-[16px]">{errorDescription}</p>
                        </div>
                        <div>
                            <p className="font-semibold text-[16px]">Corrected Words:</p>
                            <div className="flex flex-wrap gap-2">
                                {correctedWord.map((value, index) => {

                                    return (
                                        <div style={{display:"flex",gap:"10%"}}>
                                            <button
                                                key={index}
                                                onClick={() => fixWord(value.value, error.offset, error.length, index)}
                                                className="text-[12px] px-2 py-1 relative"
                                                style={{
                                                    cursor: "pointer",
                                                    border: `1px solid ${getColorByCategory(error.rule.category?.id)}`,
                                                    color: getColorByCategory(error.rule.category?.id),
                                                    width: "100px", // Set a fixed width
                                                    height: "30px", // Set a fixed height
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                                disabled={loadingIndex === index}
                                            >
                                                {loadingIndex === index ? (
                                                    <CircularProgress
                                                        size={14}
                                                        color="inherit"
                                                        style={{
                                                            position: "absolute", // Keep it centered within the button
                                                        }}
                                                    />
                                                ) : (
                                                    value.value
                                                )}
                                            </button>
                                            <button
                                                key={index}
                                                // onClick={() => fixWord(value.value, error.offset, error.length, index)}
                                                className="text-[12px] px-2 py-1 relative"
                                                style={{
                                                    cursor: "pointer",
                                                    border: `1px solid ${getColorByCategory(error.rule.category?.id)}`,
                                                    backgroundColor: getColorByCategory(error.rule.category?.id),
                                                    fontWeight:500,
                                                    color:"#fff",
                                                    width: "150px", // Set a fixed width
                                                    height: "30px", // Set a fixed height
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                                disabled={loadingIndex === index}
                                            >
                                                {loadingIndex === index ? (
                                                    <CircularProgress
                                                        size={14}
                                                        color="inherit"
                                                        style={{
                                                            position: "absolute", // Keep it centered within the button
                                                        }}
                                                    />
                                                ) : (
                                                    "Add To Dictionary"
                                                    // value.value
                                                )}
                                            </button>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ErrorWordPopup;
