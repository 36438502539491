import React, { useEffect, useState } from "react";
import "./Editor.css";
import { Img } from "@chakra-ui/react";
import BoldIcon from "../../../assets/Editor/BoldIcon.png";
import LinkIcon from "../../../assets/Editor/LinkIcon.png";
import ItalicIcon from "../../../assets/Editor/ItalicIcon.png";
import HeadingIcon from "../../../assets/Editor/HeadingIcon.png";
import UnderLineIcon from "../../../assets/Editor/UnderLineIcon.png";
import TypewriterPlaceholder from "./TypewriterPlaceholder";


// import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import Skeleton from "@mui/material/Skeleton";
export default function BasicDemo({
  setSelectedErrorKey,
  selectedErrorKey,
  setIsOpen,
  isOpen,
  results,
  newDocument,
  newSetDocument,
  setTitle,
  editorValue,
  setEditorValue,
  isMobile,
  setLoadingInner,
  editorOptions,
  handleEditorDidMount,
  editorWidth,
  setError,
  modifiedError,
  setOpen,
  setPlagDrawerOpen,
  setGrammarCorrection,
}) {
  const editorRef = React.useRef(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [highlightedContent, setHighlightedContent] = useState("");
  const [errorMap, setErrorMap] = useState({});
  const [rawText, setRawText] = useState(""); // Store plain text temporarily

  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Set loading to false after a delay
    }, 2000);
    // Customizing button text for H1 and H2
    let H1Button = document.querySelector('.ql-header[value="1"]');
    if (H1Button) {
      H1Button.innerHTML = `<img
            src="${HeadingIcon}"
            style="height: 24px; width: 24px;" alt="H1 Icon" />`;
    }
  }, []);

  const applyFormat = (command, value = "") => {
    const selectedText = window.getSelection().toString();
    if (selectedText.length === 0) return;
    document.execCommand(command, false, value);
  };
  
  const header = (
    <div className={`flex ${isMobile ? "flex-col" : "flex-row"}`}>
      <input
        type="text"
        className={` mb-0   ${
          isMobile ? "ml-0" : "ml-2"
        } text-xl w-[50%] outline-none text-[black]  font-[600]`}
        placeholder="Untitled Document"
        value={newDocument?.title}
        onChange={(e) => {
          newSetDocument({ ...newDocument, title: e.target.value });
          setTitle(e.target.value);
        }}
      />
      <span
        className={`${isMobile ? "ml-2" : "ml-0"} `}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          height: "51px",
        }}
      >
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-bold"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("bold")}
          >
            <Img style={{ height: "16px", width: "16px" }} src={BoldIcon} />
          </button>
        )}
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-link"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("createLink")}
          >
            <Img style={{ height: "16px", width: "16px" }} src={LinkIcon} />
          </button>
        )}
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-italic"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("italic")}
          >
            <Img style={{ height: "16px", width: "16px" }} src={ItalicIcon} />
          </button>
        )}
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-header"
            value="1"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("formatBlock", "h1")}
          >
            <Img src={HeadingIcon} />
          </button>
        )}
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-underline"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("underline")}
          >
            <Img
              style={{ height: "16px", width: "16px" }}
              src={UnderLineIcon}
            />
          </button>
        )}
      </span>
    </div>
  );


  // Function to get the current cursor position
  function getCursorPositionInEditor() {
    const selection = window.getSelection();
    const editorElement = editorRef.current; // Ref to your editor container
  
    if (!selection.rangeCount || !editorElement) return null;
  
    // Get the current selection range
    const range = selection.getRangeAt(0);
    const startContainer = range.startContainer;
    const startOffset = range.startOffset;
  
    // Function to count characters (including spaces) in the editor
    const countCharacters = (node) => {
      let count = 0;
      if (node.nodeType === Node.TEXT_NODE) {
        // Count all characters in text nodes, normalizing non-breaking spaces
        count = node.textContent.replace(/\u00A0/g, " ").length;
      } else {
        // Recursively count characters in child nodes
        for (let child of node.childNodes) {
          count += countCharacters(child);
        }
      }
      return count;
    };
  
    // Calculate the caret position relative to the entire editor content
    let caretPosition = 0;
    let currentNode = startContainer;
  
    // Traverse siblings and parent nodes to accumulate the character count
    while (currentNode && currentNode !== editorElement) {
      let sibling = currentNode.previousSibling;
      while (sibling) {
        caretPosition += countCharacters(sibling);
        sibling = sibling.previousSibling;
      }
      currentNode = currentNode.parentNode;
    }
  
    // Add the offset of the caret within the current node
    caretPosition += startOffset;
  
    return caretPosition;
  }
  
  const setCaretPositionInEditor = (position) => {
    const editorElement = editorRef.current;
    if (!editorElement) return;

    let charCount = 0;
    const setPosition = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const nodeLength = node.textContent.length;
        if (charCount + nodeLength >= position) {
          const range = document.createRange();
          const selection = window.getSelection();

          range.setStart(node, position - charCount);
          range.collapse(true);

          selection.removeAllRanges();
          selection.addRange(range);

          return true;
        } else {
          charCount += nodeLength;
        }
      } else {
        for (let child of node.childNodes) {
          if (setPosition(child)) return true;
        }
      }
      return false;
    };

    setPosition(editorElement);
  };

  useEffect(() => {
    
    localStorage.getItem("position");
    // Temporary fallback: show plain text before response
    const updateHighlightedContent = () => {
      const rawText = newDocument?.text || editorValue || "";
      const strippedText = stripHTML(rawText);
      setRawText(strippedText); // Set raw text for editor
      const highlighted = highlightErrors(strippedText, results);
      setHighlightedContent(highlighted); // Apply highlighting once we get response
    };
    updateHighlightedContent();

  }, [newDocument?.text, editorValue, results, selectedErrorKey]);

  const newPosition = localStorage.getItem("position");
  // if (localStorage.gr) {
  setCaretPositionInEditor(newPosition);
  // }
  const stripHTML = (text) => {
    const allowedTags = /<\/?(p|div|br)[^>]*>/i;
    return text.replace(/<\/?[^>]+>/gi, (tag) =>
      allowedTags.test(tag) ? tag : ""
    );
  };

  const sanitizePastedContent = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Remove inline styles like `font-family`
    tempElement.querySelectorAll("[style]").forEach((el) => {
      el.removeAttribute("style");
    });

    // Remove unwanted tags like <script>, <style>
    const unwantedTags = ["script", "style"];
    unwantedTags.forEach((tag) => {
      tempElement.querySelectorAll(tag).forEach((el) => el.remove());
    });

    // Remove specific classes
    tempElement.querySelectorAll("[class]").forEach((el) => {
      el.removeAttribute("class");
    });

    // Allow only certain tags and unwrap unwanted ones
    const allowedTags = /<\/?(p|div|br|span)[^>]*>/i;
    tempElement.innerHTML = tempElement.innerHTML.replace(
      /<\/?[^>]+>/gi,
      (tag) => (allowedTags.test(tag) ? tag : "")
    );

    // Return sanitized HTML
    return tempElement.innerHTML;
  };

  const highlightErrors = (text, results = []) => {
    if (!text || !results || results.length === 0) return text;
    let highlightedText = text;
    const newErrorMap = {};
    const sortedErrors = [...results].sort((a, b) => b.offset - a.offset);

    sortedErrors.forEach((error, index) => {
      const { offset, length, rule } = error;
      const partToHighlight = highlightedText.substr(offset, length);
      const color = getColorByCategory(rule.category?.id);

      // Create a unique key for each error
      const errorKey = `error-${index}`;

      // Store error details in the map
      newErrorMap[errorKey] = error;

      // Wrap the error word with a unique identifier
      const highlightedWord = `<span data-error-key="${errorKey}" style="color: ${color}; text-decoration: underline; cursor: pointer; background-color: ${
        selectedErrorKey === errorKey ? "rgba(255, 255, 0, 0.3)" : "transparent"
      } ">${partToHighlight}</span>`;

      highlightedText =
        highlightedText.slice(0, offset) +
        highlightedWord +
        highlightedText.slice(offset + length).replace(/(<\/span>);/g, "$1");
    });

    // Update the error map
    setErrorMap(newErrorMap);
    return highlightedText;
  };
  const getColorByCategory = (category) => {
    const colors = {
      TYPOS: "orange",
      SPELLING: "purple",
      TYPOGRAPHY: "#0066ff",
      CONFUSED_WORDS: "#FF7F50",
      SEMANTICS: "#ff66b2",
      STYLE: "#996633",
      MORFOLOGIK_RULE_EN_US: "#008080",
      PUNCTUATION: "#FFD700",
      MISC: "blueviolet",
      GRAMMAR: "red",
    };
    return colors[category] || "green";
  };

  const handleTextChange = (e) => {
    // getCursorPositionInEditor();
    const rawText = stripHTML(e?.htmlValue || "");
    setEditorValue(rawText); // Update editor value without HTML tags
    setLoadingInner(true);
    
    // Optionally, update rawText here to avoid unnecessary re-rendering
  };

  const handleEditorClick = (e) => {
    const errorSpan = e.target.closest("[data-error-key]");

    if (errorSpan) {
      const errorKey = errorSpan.getAttribute("data-error-key");
      const error = errorMap[errorKey];
      // Toggle the selected error key
      setSelectedErrorKey((prevKey) =>
        prevKey === errorKey ? errorKey : errorKey
      );
      if (error) {
        e.stopPropagation();

        setOpen(false);
        setGrammarCorrection(false);
        setPlagDrawerOpen(false);
        setError(error);
        setIsOpen(true);
      } else {
        setSelectedErrorKey(null);
      }
    }
  };

  return (
    <>
      <div>
        {/* Toolbar with full-width */}
        <div style={{ width: "100%" }}>{header}</div>

        {/* Centered content editor */}
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div
            style={{
              width: isMobile ? "100%" : "100%",
            }}
          >

            {loading ? (
              <>
                {/* You can also add a few small lines inside the skeleton like loading text lines */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Skeleton width="100%" height={30} animation="wave" />
                  <Skeleton width="100%" height={60} animation="wave" />
                  <Skeleton width="100%" height={120} animation="wave" />
                  <Skeleton width="100%" height={40} animation="wave" />
                  <Skeleton width="100%" height={60} animation="wave" />
                  <Skeleton width="100%" height={30} animation="wave" />
                  <Skeleton width="100%" height={60} animation="wave" />
                  <Skeleton width="100%" height={120} animation="wave" />
                </div>
              </>
            ) : (
              <div style={{ position: "relative" }}>
                {/* Placeholder animation */}
                {!(editorValue || newDocument?.text) && (
                  <div
                    style={{
                      // marginLeft: "11%",
                      marginTop: "0.5%",
                      position: "absolute",
                      top: "10px", // Adjust to fit your needs
                      left: "1.8%", // Adjust to fit your needs
                      pointerEvents: "none", // Allow typing without affecting the placeholder
                      color: "#888",
                      fontSize: "16px",
                    }}
                  >
                    <TypewriterPlaceholder />
                  </div>
                )}

                <div
                  ref={editorRef}
                  className="text-lg  caret-gray-700 outline-none px-3border-b-2 mt-2 scrollbar-thin"
                  dangerouslySetInnerHTML={{
                    __html: highlightedContent || rawText,
                  }}
                  style={{ width: "100%", overflow: "auto", height: "75vh" }}
                  contentEditable
                  suppressContentEditableWarning
                  onClick={!isMobile ? handleEditorClick : null} // Mobile view mein onClick ko disable karein
                  onInput={(e) => {
                    const htmlValue = e.currentTarget.innerHTML;
                    setEditorValue(htmlValue);
                    handleTextChange({ htmlValue });
                    const position = getCursorPositionInEditor();
                    localStorage.setItem("position", position);
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    const pastedContent =
                      (e.clipboardData || window.Clipboard).getData(
                        "text/html"
                      ) ||
                      (e.clipboardData || window.Clipboard).getData(
                        "text/plain"
                      );

                    const sanitizedContent = sanitizePastedContent(
                      pastedContent
                    ).replace(/&nbsp;/g, " ");
                    document.execCommand("insertHTML", false, sanitizedContent);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
