import React, { useRef, useEffect, useState } from "react";
import "./PromptsPopup.css";
import closeIcon from "../../assets/close-icon.png";

function PromptsPopup({ onClose, setInputText }) {
  const categories = [
    {
      heading: "Content & SEO",
      subheadings: [
        {
          title: "Article Generator",
          content:
            "Write an article about [topic]. Include relevant statistics (with source links), diverse perspectives, and actionable insights. Write in a [X tone] (e.g., professional, conversational). Ensure it is SEO-optimized with appropriate headers and subheaders.",
        },
        {
          title: "Blog Outline",
          content:
            "Generate a detailed blog outline for [topic]. Include the title, introduction, main sections, subheadings, and conclusion. Suggest keywords for SEO and provide a brief description for each section.",
        },
        {
          title: "Blog Post Generator",
          content:
            "Write a blog post on [topic] aimed at [target audience]. Include relevant keywords, engaging content, and a strong call-to-action. Make it suitable for SEO and include subheadings for readability.",
        },
        {
          title: "SEO Content Brief",
          content:
            "Create an SEO content brief for [topic]. Include target keywords, suggested word count, competitor references, content goals, and tone of voice. Outline any required sections and headers.",
        },
        {
          title: "SEO Keyword Ideas",
          content:
            "Generate a list of SEO keywords for [topic]. Include primary keywords, long-tail keywords, and related phrases. Highlight keyword difficulty and search volume (if possible).",
        },
        {
          title: "Headline Generator",
          content:
            "Generate 10 engaging headlines for [topic]. Focus on capturing the audience's attention while incorporating target keywords. Provide a mix of listicle, question, and actionable formats.",
        },
        {
          title: "FAQ Generator",
          content:
            "Create a list of frequently asked questions for [topic]. Ensure they are relevant to [industry/audience]. Include concise, helpful answers and structure them for SEO optimization.",
        },
        {
          title: "Backlink Outreach Email",
          content:
            "Write a professional and persuasive backlink outreach email to [recipient/company]. Highlight the value of linking to [content/page]. Include a clear call-to-action and maintain a polite tone.",
        },
        {
          title: "Short Summary",
          content:
            "Summarize [topic/content] in [X words]. Ensure the summary is concise, engaging, and captures the key points. Suitable for meta descriptions or quick overviews.",
        },
        {
          title: "Product Descriptions",
          content:
            "Write a compelling product description for [product]. Include key features, benefits, and a clear call-to-action. Optimize the content for SEO with relevant keywords.",
        },
        {
          title: "Landing Page Copy",
          content:
            "Write conversion-focused copy for a landing page about [product/service]. Include a strong headline, engaging subheadings, benefits, and a clear call-to-action. Optimize for SEO and readability.",
        },
      ],
    },
    {
      heading: "Recruitment & Talent Acquisition",
      subheadings: [
        {
          title: "Job Description Generator",
          content:
            "Create a detailed job description for the position of [role]. Include key responsibilities, qualifications, preferred skills, and company culture details. Ensure the tone is professional and aligned with [company values/industry].",
        },
        {
          title: "Personalized Outreach Email",
          content:
            "Write a personalized outreach email to [candidate name] for the role of [position]. Highlight their [specific skills/experience] and explain why they would be a great fit for [company name]. Include a clear call-to-action and maintain a professional yet approachable tone.",
        },
        {
          title: "Interview Questions Generator",
          content:
            "Generate a list of interview questions for the role of [position]. Include questions to assess technical skills, soft skills, and cultural fit. Separate them into categories like behavioral, situational, and role-specific questions.",
        },
        {
          title: "Candidate Evaluation Framework",
          content:
            "Create a candidate evaluation framework for [role]. Include criteria for [skills, experience, cultural fit], scoring rubrics, and a section for interview notes. Ensure it’s structured for easy use during interviews.",
        },
        {
          title: "Offer Letter Templates",
          content:
            "Draft an offer letter for [candidate name] for the position of [role]. Include details like start date, salary, benefits, and key terms. Maintain a professional tone and include placeholders for customization.",
        },
        {
          title: "LinkedIn Outreach Templates",
          content:
            "Write a LinkedIn connection request and follow-up message for [candidate name]. Highlight their [specific skills/achievements] and express interest in discussing a potential opportunity at [company name].",
        },
        {
          title: "Recruitment Ad Copy",
          content:
            "Create a recruitment ad for the role of [position]. Focus on [key benefits of the role/company culture]. Use engaging language to attract [specific target audience] and include a strong call-to-action to apply.",
        },
      ],
    },
    {
      heading: "Sales & Lead Generation",
      subheadings: [
        {
          title: "Cold Email Template",
          content:
            "Write a cold email to [prospect name] introducing [product/service]. Highlight [specific pain point] and explain how [product/service] solves it. Include a clear call-to-action and maintain a professional yet engaging tone.",
        },
        {
          title: "Lead Scoring Framework",
          content:
            "Create a lead scoring framework for [industry/product]. Include criteria like [engagement level, company size, budget, timeline] and assign weights for each criterion. Ensure it’s easy to customize for different sales pipelines.",
        },
        {
          title: "Sales Pitch Generator",
          content:
            "Write a compelling sales pitch for [product/service]. Include a strong opening, key benefits, a brief success story, and a closing call-to-action. Tailor the pitch for [specific audience/industry].",
        },
        {
          title: "Follow-up Email Sequences",
          content:
            "Draft a follow-up email sequence for [prospect type] after [initial meeting/demo]. Include 3-4 emails, each with a specific focus (e.g., recap, value-add, urgency). Keep the tone professional and engaging.",
        },
        {
          title: "Call Script Generator",
          content:
            "Create a call script for a sales conversation with [prospect type]. Include an opening hook, questions to identify needs, a value proposition for [product/service], and a closing statement with a call-to-action.",
        },
        {
          title: "Personalized Proposal Templates",
          content:
            "Write a personalized proposal for [client name] detailing [product/service]. Include an introduction, tailored benefits, pricing, timeline, and next steps. Maintain a professional tone and make it visually appealing.",
        },
        {
          title: "Client Onboarding Plan",
          content:
            "Develop a client onboarding plan for [product/service]. Include steps like [kickoff meeting, account setup, training sessions, success metrics review]. Ensure it’s easy to follow and customizable for different clients.",
        },
      ],
    },
    {
      heading: "Social Media Management",
      subheadings: [
        {
          title: "Social Media Caption Generator",
          content:
            "Write an engaging caption for a [platform] post about [topic/product]. Use a tone that is [humorous/informative/insightful], include a call-to-action, and keep it under [X characters].",
        },
        {
          title: "Instagram Hashtag Suggestions",
          content:
            "Generate a list of effective Instagram hashtags for [topic/product]. Include a mix of trending, niche, and broad hashtags to maximize reach and engagement.",
        },
        {
          title: "LinkedIn Post Templates",
          content:
            "Draft a professional LinkedIn post for [topic]. Highlight key insights, include a clear call-to-action, and structure it to drive engagement among [target audience].",
        },
        {
          title: "Twitter Thread Creator",
          content:
            "Write a Twitter thread about [topic/product]. Break the information into [X tweets], starting with a strong hook and ending with a clear call-to-action or final thought.",
        },
        {
          title: "TikTok Video Script Ideas",
          content:
            "Create a TikTok video script for [topic/product]. Include an attention-grabbing opening, key talking points, and a creative call-to-action. Suggest any relevant trends or audio to pair with the video.",
        },
        {
          title: "Content Calendar Generator",
          content:
            "Develop a 30-day social media content calendar for [topic/product]. Include post ideas for each day, specifying the platform, content type, and key goals (e.g., engagement, awareness, conversion).",
        },
        {
          title: "Viral Campaign Ideas",
          content:
            "Brainstorm 5 viral social media campaign ideas for [product/service]. Focus on innovative concepts that resonate with [target audience] and leverage current trends or challenges.",
        },
      ],
    },
    {
      heading: "Business Strategy",
      subheadings: [
        {
          title: "SWOT Analysis Generator",
          content:
            "Create a SWOT analysis for [company/product]. Include detailed points for strengths, weaknesses, opportunities, and threats, tailored to [specific market/industry].",
        },
        {
          title: "Business Plan Framework",
          content:
            "Develop a business plan framework for [company/product]. Include sections for executive summary, market analysis, operations, marketing, financial projections, and a clear roadmap.",
        },
        {
          title: "Go-to-Market Strategy",
          content:
            "Design a go-to-market strategy for [product/service]. Include target audience, positioning, pricing, key distribution channels, and marketing tactics to ensure a successful launch.",
        },
        {
          title: "Customer Persona Builder",
          content:
            "Generate a detailed customer persona for [product/service]. Include demographics, goals, pain points, preferred communication channels, and buying behavior.",
        },
        {
          title: "Competitive Analysis Guide",
          content:
            "Draft a competitive analysis for [company/product]. Compare [company name] with [key competitors] based on features, pricing, target audience, and market positioning. Highlight areas where [company name] has a competitive edge.",
        },
        {
          title: "Market Entry Strategy",
          content:
            "Create a market entry strategy for [product/service] targeting [region/industry]. Include steps for market research, positioning, pricing, and distribution channels to ensure a smooth entry.",
        },
        {
          title: "Value Proposition Canvas",
          content:
            "Develop a value proposition canvas for [product/service]. Map out the customer jobs, gains, and pains, and align them with the product’s features, benefits, and unique selling points.",
        },
      ],
    },
    {
      heading: "Ideation & Innovation",
      subheadings: [
        {
          title: "Brainstorming Ideas Generator",
          content:
            "Generate a list of innovative ideas for [problem/product]. Provide at least [X ideas], focusing on creativity and practicality. Ensure the ideas align with [specific goals/target audience].",
        },
        {
          title: "Product Launch Checklist",
          content:
            "Create a comprehensive product launch checklist for [product/service]. Include pre-launch activities, marketing tasks, sales enablement, and post-launch follow-up.",
        },
        {
          title: "Problem-Solution Framework",
          content:
            "Draft a problem-solution framework for [challenge]. Clearly define the problem, analyze its impact, and provide actionable solutions with measurable outcomes.",
        },
        {
          title: "Innovation Process Workflow",
          content:
            "Design an innovation workflow for [project/idea]. Include steps like ideation, prototyping, validation, iteration, and final implementation. Provide a timeline for each phase.",
        },
        {
          title: "Blue Ocean Strategy Tools",
          content:
            "Create a Blue Ocean Strategy framework for [company/product]. Identify untapped markets, eliminate unnecessary features, and focus on value innovation to differentiate from competitors.",
        },
        {
          title: "Idea Validation Checklist",
          content:
            "Develop an idea validation checklist for [concept/product]. Include steps for testing market demand, gathering feedback, assessing feasibility, and iterating based on insights.",
        },
        {
          title: "AI Prompt Writer",
          content:
            "Write a custom AI prompt for [specific task]. Ensure it is clear, actionable, and includes all necessary inputs to generate high-quality outputs for [intended purpose].",
        },
      ],
    },
    {
      heading: "Email Marketing",
      subheadings: [
        {
          title: "Attention-Interest-Desire-Action (AIDA) Emails",
          content:
            "Write an email using the AIDA framework to promote [product/service]. Grab attention with a strong opening, build interest with key features, create desire with benefits, and end with a clear call-to-action.",
        },
        {
          title: "Features-Advantages-Benefits (FAB) Templates",
          content:
            "Create an email highlighting the features, advantages, and benefits of [product/service]. Make it clear how each feature directly impacts and solves [specific customer pain points].",
        },
        {
          title: "Newsletter Topics",
          content:
            "Suggest a list of engaging newsletter topics for [industry/product]. Ensure topics are informative, value-driven, and tailored to [target audience]. Include subject line ideas for each topic.",
        },
        {
          title: "Drip Campaign Sequences",
          content:
            "Design a 5-step drip email campaign for [product/service]. Include email objectives for each step (e.g., awareness, consideration, conversion), suggested content, and clear calls-to-action.",
        },
        {
          title: "Email Subject Line Ideas",
          content:
            "Generate 10 engaging email subject lines for [topic/product]. Focus on increasing open rates by using curiosity, urgency, or personalization. Keep each line under [X characters].",
        },
        {
          title: "Customer Retention Email Templates",
          content:
            "Write a customer retention email for [target audience]. Highlight their value to [company name], offer an exclusive incentive (e.g., discounts, rewards), and include a call-to-action to re-engage.",
        },
      ],
    },
    {
      heading: "Paid Advertising",
      subheadings: [
        {
          title: "Facebook Ad Copy Generator",
          content:
            "Write a compelling Facebook ad copy for [product/service]. Include a hook to grab attention, key benefits of [product/service], and a strong call-to-action. Keep it concise and suitable for [target audience].",
        },
        {
          title: "Google Ads Headline Suggestions",
          content:
            "Generate 10 engaging Google Ads headlines for [product/service]. Focus on incorporating target keywords, solving [specific customer pain points], and staying within the character limit.",
        },
        {
          title: "Instagram Ad Caption Generator",
          content:
            "Write an engaging Instagram ad caption for [product/service]. Include a relatable hook, key benefits, and a clear call-to-action. Suggest relevant emojis and hashtags to boost visibility.",
        },
        {
          title: "YouTube Ad Script Creator",
          content:
            "Draft a 30-second YouTube ad script for [product/service]. Include a catchy opening, a brief explanation of benefits, and a strong closing call-to-action. Make it conversational and visually engaging.",
        },
        {
          title: "Call-to-Action Templates",
          content:
            "Create a list of 10 powerful call-to-action phrases for [product/service]. Tailor them for different platforms (e.g., “Shop Now,” “Learn More,” “Download Free Guide”) and objectives.",
        },
        {
          title: "Ad Performance Testing Ideas",
          content:
            "Suggest a list of A/B testing ideas for [ad campaign]. Include variables like headlines, ad copy, visuals, targeting criteria, and call-to-actions to optimize performance.",
        },
      ],
    },
    {
      heading: "PR & Communications",
      subheadings: [
        {
          title: "Press Release Generator",
          content:
            "Write a press release announcing [event/product launch/news]. Include a compelling headline, an engaging opening paragraph, key details, quotes from leadership, and contact information. Maintain a professional and newsworthy tone.",
        },
        {
          title: "Customer Case Study Framework",
          content:
            "Create a customer case study outline for [company/product]. Include sections for background, challenges faced, solutions implemented, measurable results, and customer testimonials. Ensure it highlights the value of [product/service].",
        },
        {
          title: "Internal Memo Templates",
          content:
            "Draft an internal memo for [topic]. Include a clear subject line, concise content explaining the purpose, and action points if applicable. Keep it professional and easy to understand for [target audience/team].",
        },
        {
          title: "Media Outreach Email Templates",
          content:
            "Write an email to pitch [news/event] to [journalist/media outlet]. Include a strong subject line, an engaging introduction, key details of the story, and contact information for follow-up. Keep it concise and persuasive.",
        },
        {
          title: "Event Invitation Templates",
          content:
            "Create an event invitation email for [event name]. Include key details like date, time, location, and purpose of the event. Use a warm and inviting tone, and add a call-to-action to RSVP.",
        },
        {
          title: "Product Brochure Content",
          content:
            "Write product brochure content for [product/service]. Include an engaging introduction, key features and benefits, use cases, and a strong call-to-action. Ensure it’s visually appealing and easy to skim.",
        },
      ],
    },
    {
      heading: "E-Learning & Academia",
      subheadings: [
        {
          title: "Course Outline Generator",
          content:
            "Create a detailed course outline for [subject/course name]. Include module titles, learning objectives, key topics, and suggested assignments or activities for each module. Ensure it aligns with [target audience/academic level].",
        },
        {
          title: "Study Material Creator",
          content:
            "Generate concise and engaging study material for [topic]. Include key concepts, examples, and diagrams (if applicable) to help students understand and retain the content effectively.",
        },
        {
          title: "Online Quiz Questions Generator",
          content:
            "Create a set of [X] online quiz questions for [topic]. Include multiple-choice, true/false, and short-answer questions, along with correct answers and explanations for each.",
        },
        {
          title: "Lesson Plan Templates",
          content:
            "Design a lesson plan for [topic] for [target audience/grade level]. Include objectives, an introduction, teaching methods, activities, and a conclusion. Provide time estimates for each section.",
        },
        {
          title: "Assignment Brief Templates",
          content:
            "Draft an assignment brief for [topic]. Include clear instructions, objectives, required format, word count, and submission guidelines. Add evaluation criteria for grading.",
        },
        {
          title: "Research Proposal Writer",
          content:
            "Write a research proposal for [topic]. Include a title, background, research objectives, methodology, expected outcomes, and references. Ensure it’s structured for academic or professional review.",
        },
      ],
    },
    {
      heading: "Blogging & Writing",
      subheadings: [
        {
          title: "Creative Story Generator",
          content:
            "Write a creative story based on [theme/idea]. Include engaging characters, a compelling plot, and a meaningful conclusion. Use a tone and style suited for [target audience].",
        },
        {
          title: "Guest Post Outline",
          content:
            "Create an outline for a guest post on [topic]. Include an attention-grabbing title, an introduction, main points with subheadings, and a conclusion with a call-to-action.",
        },
        {
          title: "Content Rewrite Tools",
          content:
            "Rewrite the following content to make it [more engaging/formal/informal/professional]:[Insert Content]. Ensure clarity, improved flow, and maintain the original meaning.",
        },
        {
          title: "Grammar and Style Check",
          content:
            "Review the following text for grammar and style improvements: [Insert Content]. Highlight errors and suggest edits to enhance readability and tone.",
        },
        {
          title: "Editing & Proofreading Tips",
          content:
            "Provide editing and proofreading tips for [type of content]. Focus on grammar, structure, tone, and clarity. Suggest tools or techniques for efficient proofreading.",
        },
        {
          title: "Topic Brainstorming Ideas",
          content:
            "Generate a list of [X] blog post ideas for [industry/niche]. Ensure the topics are engaging, relevant, and cater to [target audience’s] interests and needs.",
        },
      ],
    },
    {
      heading: "Customer Engagement",
      subheadings: [
        {
          title: "Personalized FAQ Generator",
          content:
            "Generate a list of frequently asked questions for [product/service]. Tailor the questions to address [specific customer needs] and provide concise, helpful answers.",
        },
        {
          title: "Live Chat Response Templates",
          content:
            "Create live chat response templates for [common inquiries]. Include polite openings, clear solutions, and friendly closings to enhance the customer experience.",
        },
        {
          title: "Client Feedback Email Templates",
          content:
            "Draft an email requesting feedback from [client name] about their experience with [product/service]. Use a friendly tone and include specific questions or a feedback form link.",
        },
        {
          title: "Customer Testimonial Request",
          content:
            "Write a personalized email requesting a testimonial from [customer name]. Highlight the impact of [product/service] and suggest key points they might include.",
        },
        {
          title: "Loyalty Program Ideas",
          content:
            "Suggest creative loyalty program ideas for [business type]. Focus on benefits like [discounts, exclusive access, rewards] to increase customer retention and engagement.",
        },
      ],
    },
    {
      heading: "Productivity & Workflow",
      subheadings: [
        {
          title: "Task Prioritization Framework",
          content:
            "Create a task prioritization framework for [project/team]. Include categories like urgency, importance, and effort required to help organize and prioritize tasks effectively.",
        },
        {
          title: "Daily Planner Generator",
          content:
            "Generate a daily planner template for [specific role]. Include sections for tasks, appointments, goals, and notes to maximize productivity.",
        },
        {
          title: "Meeting Agenda Templates",
          content:
            "Draft a meeting agenda for [topic]. Include a clear objective, discussion points, time allocations, and a section for action items or follow-ups.",
        },
        {
          title: "Project Milestone Checklist",
          content:
            "Create a milestone checklist for [project]. Include key deliverables, deadlines, and responsible team members for each milestone.",
        },
        {
          title: "Workflow Automation Suggestions",
          content:
            "Suggest workflow automation ideas for [specific process/team]. Include tools or platforms that can streamline repetitive tasks and improve efficiency.",
        },
      ],
    },
    {
      heading: "Brand Building",
      subheadings: [
        {
          title: "Brand Story Template",
          content:
            "Write a compelling brand story for [company/brand name]. Include the origin, mission, values, and unique journey that differentiate it in [industry/market].",
        },
        {
          title: "Brand Voice Guidelines",
          content:
            "Develop brand voice guidelines for [company/brand name]. Specify tone, language style, and dos/don’ts to ensure consistent messaging across all channels.",
        },
        {
          title: "Tagline and Slogan Creator",
          content:
            "Generate 10 creative taglines or slogans for [brand/product]. Focus on conveying [key message/USP] in a concise and memorable way.",
        },
        {
          title: "Mission and Vision Statements",
          content:
            "Write a mission and vision statement for [company/brand]. Reflect the core purpose, long-term goals, and the value it aims to deliver to [target audience].",
        },
        {
          title: "Brand Messaging Playbook",
          content:
            "Create a brand messaging playbook for [company/brand]. Include key messages for different audiences, elevator pitches, and guidelines for maintaining consistency in communication.",
        },
      ],
    },
    {
      heading: "Market Research",
      subheadings: [
        {
          title: "Industry Trends Analysis",
          content:
            "Analyze the latest trends in [industry]. Highlight key shifts, emerging opportunities, and their potential impact on [business/product].",
        },
        {
          title: "Customer Behavior Insights",
          content:
            "Generate insights into customer behavior for [target audience/industry]. Focus on purchasing patterns, preferences, and pain points to better inform marketing strategies.",
        },
        {
          title: "Market Size Estimation Framework",
          content:
            "Create a framework to estimate the market size for [product/service]. Include TAM (Total Addressable Market), SAM (Serviceable Available Market), and SOM (Serviceable Obtainable Market).",
        },
        {
          title: "Competitive Pricing Analysis",
          content:
            "Conduct a competitive pricing analysis for [product/service]. Compare [company name]’s pricing with [competitors], considering features, target audience, and market positioning.",
        },
        {
          title: "Demand Forecasting Models",
          content:
            "Develop a demand forecasting model for [product/service]. Include factors like historical sales data, market trends, seasonality, and customer preferences to predict future demand.",
        },
      ],
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editorContent, setEditorContent] = useState(""); // Tracks content in the editor

  const popupRef = useRef(null);

  // Close the popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handlePromptClick = (content) => {
    setEditorContent(content); // Update the editor with the selected prompt content
  };

  const handleUsePrompt = () => {
    setInputText(editorContent); // Update the parent with the edited content
    onClose(); // Close the popup
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container" ref={popupRef}>
        {/* Close Button */}
        <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </button>
        <h3 className="popup-heading">Prompts</h3>

        <div className="popup-content">
          {/* Categories Section */}
          <div className="categories-section">
            {categories.map((category, index) => (
              <div key={index} className="category">
                <h3
                  className={`category-heading ${
                    selectedCategory === index ? "active" : ""
                  }`}
                  onClick={() =>
                    setSelectedCategory(
                      selectedCategory === index ? null : index
                    )
                  }
                >
                  {category.heading}
                </h3>
                {selectedCategory === index && (
                  <div className="subheadings">
                    {category.subheadings.map((sub, subIndex) => (
                      <button
                        key={subIndex}
                        className="prompt-button"
                        onClick={() => handlePromptClick(sub.content)}
                      >
                        {sub.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Editor Section */}
          <div className="editor-section">
            <h4>Editor</h4>
            <textarea
              value={editorContent}
              onChange={(e) => setEditorContent(e.target.value)} // Reflect user changes
              className="editor-textarea"
              placeholder="Selected prompt content will appear here...                      or make your Custom Prompts..."
            />
            <button className="use-prompt-button" onClick={handleUsePrompt}>
              Use Prompt
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromptsPopup;
