import { Img } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import newDocumentIcon from "../assets/Editor/newDocumentIcon.png";
import uploadIcon from "../assets/Editor/uploadIcon.png";
import DownloadFileIcon from "../assets/Editor/DownloadFileIcon.png";
import printIcon from "../assets/Editor/printIcon.png";
import { toast } from "react-toastify";
import axios from "../libs/axios";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import { RxCross2 } from "react-icons/rx";
import { motion } from "framer-motion";
import {
  UserContext,
  UserDispatchContext,
  useUpgradeModal,
} from "./context/user";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

// const drawerVariants = {
//   open: {
//     x: 0, // Slide into view
//     opacity: 1,
//     transition: {
//       type: "tween",
//       duration: 0.3,
//     },
//   },
//   closed: {
//     x: "100%", // Slide out of view (to the right)
//     opacity: 0,
//     transition: {
//       type: "tween",
//       duration: 0.3,
//     },
//   },
// };

const Leftpane = ({ toolbarRef, setMenuOpen, menuOpen, currentDocument }) => {
  const [isDocumentsOpen, setIsDocumentsOpen] = useState(false);
  const { setshowupgrade } = useUpgradeModal();
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 900px)");

  const Items = [
    { name: "Home", path: "/dashboard" },
    { name: "Documents", path: "" },
  ];

  const convertHtmlToRtf = (html) => {
    // RTF header
    let rtf = "{\\rtf1\\ansi\\deff0";

    // Font table
    rtf += "{\\fonttbl{\\f0\\fswiss\\fcharset0 Arial;}}";

    // Create temporary element to parse HTML
    const temp = document.createElement("div");
    temp.innerHTML = html;

    // Process the content
    const processNode = (node) => {
      let result = "";

      if (node.nodeType === 3) {
        // Text node
        result += node.textContent.replace(/[\\{}]/g, "\\$&");
      } else if (node.nodeType === 1) {
        // Element node
        const tag = node.tagName.toLowerCase();

        // Handle different HTML tags
        if (tag === "em" || tag === "i") {
          result += "{\\i ";
        } else if (tag === "strong" || tag === "b") {
          result += "{\\b ";
        } else if (tag === "p") {
          result += "\\par ";
        }

        // Process child nodes
        Array.from(node.childNodes).forEach((child) => {
          result += processNode(child);
        });

        // Close formatting if needed
        if (tag === "em" || tag === "i" || tag === "strong" || tag === "b") {
          result += "}";
        }
      }

      return result;
    };

    // Process the content and add to RTF
    rtf += processNode(temp);

    // Close RTF document
    rtf += "}";

    return rtf;
  };

  const handleDownload = (e) => {
    e.preventDefault();
    try {
      if (!currentDocument?.text) {
        toast.error("No document content to download");
        return;
      }

      // Convert HTML to RTF
      const rtfContent = convertHtmlToRtf(currentDocument.text);

      // Create blob with RTF content
      const blob = new Blob([rtfContent], { type: "application/rtf" });

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Set filename with .rtf extension
      const filename = `${currentDocument.title || "document"}.rtf`;
      link.setAttribute("download", filename);

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("Document downloaded successfully!");
    } catch (error) {
      console.error("Download failed:", error);
      toast.error("Failed to download document");
    }
  };

  const handlePrint = (e) => {
    e.preventDefault();
    try {
      if (!currentDocument?.text) {
        toast.error("No document content to print");
        return;
      }

      // Create a new window for printing
      const printWindow = window.open("", "Print Window");

      // Create the print content
      const printContent = `
        <html>
          <head>
            <title>${currentDocument.title || "Document"}</title>
            <style>
              body { font-family: Arial, sans-serif; }
              h1 { margin-top: 0; }
            </style>
          </head>
          <body>
            <h1>${currentDocument.title || "Document"}</h1>
            <p>${currentDocument.text}</p>
          </body>
        </html>
      `;

      // Write the content to the new window and print
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.print();

      // Close the new window
      printWindow.close();

      toast.success("Document sent to printer!");
    } catch (error) {
      console.error("Print failed:", error);
      toast.error("Failed to print document");
    }
  };
  const newDocument = async () => {
    try {
      const newDocRes = await axios.post("/api/document");
      if (newDocRes?.data?.notSubscribed) {
        setshowupgrade(true);
        return;
      }

      setDocuments(
        documents.length > 0
          ? [...documents, newDocRes.data.data]
          : [newDocRes.data.data]
      );
      navigate(`/editor/${newDocRes.data.data._id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const documentSubItems = [
    // {
    //     name: "New Document",
    //     icon: <Img
    //         style={{ height: "16px", width: "16px", marginTop: "2px" }}
    //         src={newDocumentIcon} />,
    //     onClick : newDocument
    // },
    // {
    //     name: "Upload File", icon: <Img
    //         style={{ height: "16px", width: "16px", marginTop: "2px" }}
    //         src={uploadIcon}

    //     />, path: "/upload-file"
    // },
    {
      name: "Download",
      icon: (
        <Img
          style={{ height: "20px", width: "20px", marginTop: "2px" }}
          src={DownloadFileIcon}
        />
      ),
      path: "/download",
      onClick: handleDownload,
    },
    {
      name: "Print",
      icon: (
        <Img
          style={{ height: "20px", width: "20px", marginTop: "2px" }}
          src={printIcon}
        />
      ),
      onClick: (e) => {
        setMenuOpen(false);
        setTimeout(() => {
          handlePrint(e);
        }, 100);
      },
    },
  ];

  const toggleDocuments = () => {
    setIsDocumentsOpen(!isDocumentsOpen);
  };
  {
    /* <motion.div
      initial={{ x: "100%" }} // Start off-screen to the left
      animate={{ x: "-30%" }} // Slide in to its original position
      exit={{ x: "100%" }} // Slide out back to the left
      transition={{ type: "tween", duration: 0.4 }} // Smooth animation
      className={`absolute h-[92.5vh] ${isMobile ? "ml-0 w-[100%] sm:[50%] " : " w-[200px]"
        } mt-4 bg-white border-2`}
      style={{ boxShadow: " rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
    ></motion.div> */
  }
  return (
    <div className="">
      {isMobile ? (
        <div>
          <motion.div
            initial={{ x: "100%" }} // Start completely off the screen (to the right)
            animate={{ x: menuOpen ? 0 : "100%" }} // Slide in and out
            transition={{
              duration: 0.3, // Set the duration to 0.3 seconds for faster animation
              ease: "easeInOut",
            }}
            className="fixed  px-1 py-2 right-0 h-full w-52 bg-white border-l border-t text-white "
          >
            <div className="text-black flex justify-end py-2 pr-2">
              
              <RxCross2
                size={25}
                className=""
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  // e.stopPropagation(); 
                }}
                
              />
            </div>
            {Items.map((item) => (
              <>
                <Link to={item?.path}>
                  {" "}
                  <div
                    key={item.name}
                    style={{
                      display: "flex",
                      gap: "5%",
                      alignItems: "center",
                      padding: "2%",
                      paddingLeft: "5%",
                      cursor: "pointer",
                    }}
                    onClick={item.name === "Documents" ? toggleDocuments : null}
                    className="flex items-center"
                  >
                    <Link to={item?.path}>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          color: "#040404",
                          marginTop: "10%",
                        }}
                      >
                        {item.name}
                      </div>
                    </Link>
                    {item.name === "Documents" && (
                      <>
                        {isDocumentsOpen ? (
                          <IoIosArrowUp className="text-black mt-2" />
                        ) : (
                          <IoIosArrowDown className="text-black mt-2" />
                        )}
                      </>
                    )}
                  </div>
                </Link>
                {/* <hr style={{ width: "90%", marginLeft: "3%" }} /> */}

                {item.name === "Documents" && isDocumentsOpen && (
                  <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                    {documentSubItems.map((subItem) => (
                      <Link
                        to={subItem.path}
                        key={subItem.name}
                        onClick={subItem.onClick}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "#040404",
                            marginTop: "10px",
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>
                            {subItem.icon}
                          </span>
                          {subItem.name}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ))}
          </motion.div>
        </div>
      ) : (
        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }} // Slide in to its original position
          exit={{ x: "100%" }} // Slide out back to the left
          transition={{ type: "tween", duration: 0.4 }} // Smooth animation
          className={`absolute h-[100vh]  w-[200px]
      }  bg-white`}
          style={{ boxShadow: " rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
        >
          <div className="text-end px-2 pt-2">
            <CloseIcon
              sx={{
                // position: "absolute",
                top: 0,
                cursor: "pointer",
                fontSize: "24px",
              }}
              onClick={() => setMenuOpen(!menuOpen)}
            />
          </div>
          {Items.map((item) => (
            <>
              <Link to={item?.path}>
                {" "}
                <div
                  key={item.name}
                  style={{
                    display: "flex",
                    gap: "5%",
                    alignItems: "center",
                    padding: "2%",
                    paddingLeft: "5%",
                    cursor: "pointer",
                  }}
                  onClick={item.name === "Documents" ? toggleDocuments : null}
                >
                  <Link to={item?.path}>
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#040404",
                        marginTop: "10%",
                      }}
                    >
                      {item.name}
                    </div>
                  </Link>
                  {item.name === "Documents" && (
                    <>
                      {isDocumentsOpen ? (
                        <IoIosArrowUp className="text-black mt-2" />
                      ) : (
                        <IoIosArrowDown className="text-black mt-2" />
                      )}
                    </>
                  )}
                </div>
              </Link>
              {/* <hr style={{ width: "90%", marginLeft: "3%" }} /> */}

              {item.name === "Documents" && isDocumentsOpen && (
                <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                  {documentSubItems.map((subItem) => (
                    <Link
                      to={subItem.path}
                      key={subItem.name}
                      onClick={subItem.onClick}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          color: "#040404",
                          marginTop: "10px",
                        }}
                      >
                        <span style={{ marginRight: "8px" }}>
                          {subItem.icon}
                        </span>
                        {subItem.name}
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </>
          ))}
          {/* <div ref={toolbarRef} className="mt-[20px] h-full d-flex"></div> */}
        </motion.div>
      )}
    </div>
  );
};

export default Leftpane;
