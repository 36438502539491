import React, { useEffect, useState } from "react";
import arrow_Down from "../../assets/Suggestions/chevron-down.png";
import { getWordAt, countWords } from "../../libs/utility";
import SuggestionCard from "../Elements/SuggestionCard";
import crossIcon from "../../assets/Editor/grammarCorrectionCrossIcon.png";
import { motion } from "framer-motion";
import { Img, useMediaQuery } from "@chakra-ui/react";
import noCorrectionIcon from "../../assets/Editor/noCorrectionIcon2.png"
const CUSTOM_GREEN = "customGreen";
const CUSTOM_YELLOW = "customYellow";
const CUSTOM_ORANGE = "customOrange";

const drawerVariants = {
  open: {
    x: 0, // Slide into view
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.3,
    },
  },
  closed: {
    x: "100%", // Slide out of view (to the right)
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.3,
    },
  },
};

const ArrowDown = () => (
  <img className="absolute right-0" src={arrow_Down} alt="dropdown" />
);

const ArrowUp = () => (
  <img
    className="absolute right-0 top-0 rotate-180"
    src={arrow_Down}
    alt="dropdown"
  />
);

const SuggestionCagegory = ({
  categoryName,
  suggestionCards,
  bgColor,
  isCorrectWord,
}) => {
  const [arrow_typo, setArrow_typo] = useState(false);

  const getSuggestionCardLength = () => {
    let actualLength = suggestionCards?.map((card) => {
      let suggestionLength;
      if (card?.props?.result?.replacements) {
        if (card?.props?.correctWord || card?.props?.result?.replacements) {
          suggestionLength = card?.props?.result?.replacements.length;
        }
      }
      // else if(card?.replacements.length) {
      //   suggestionLength = card?.replacements?.length
      // }
      return suggestionLength;
    });

    let count = 0;
    // eslint-disable-next-line
    let countLength = actualLength?.map((item) => {
      if (item > 0) {
        count++;
      }
      return count;
    });

    return count;
  };


  return (
    <>
      {suggestionCards}
      {/* <div      
        onClick={(e) => {
          suggestionCards.length > 0 && setArrow_typo(!arrow_typo);
        }}
        className={`mt-3 pl-4 py-2 flex relative ${bgColor} rounded-[0.4rem] text-[#FFFFFF] text-[18px] font-[600] shadow-[0px_-4px_0px_-2px_rgba(223, 201, 0, 1) cursor-pointer`}
      >
        {categoryName} (
        {suggestionCards?.length ? getSuggestionCardLength() : 0})
        {arrow_typo === true && suggestionCards.length > 0 ? (
          <ArrowUp />
        ) : (
          <ArrowDown />
        )}
      </div>
      <motion.div
        initial={{
          display: arrow_typo ? "block" : "none",
          height: arrow_typo ? "auto" : "0px",
        }}
        animate={{
          display: arrow_typo ? "block" : "none",
          height: arrow_typo ? "auto" : "0px",
        }}
        exit={{
          height: arrow_typo ? "0px" : "auto",
        }}
        transition={{ duration: 0.3 }}
      >
        {suggestionCards}
      </motion.div> */}
    </>
  );
};

const Suggestions = ({
  grammarCorrection,
  setGrammarCorrection,
  view,
  setView,
  closeSuggestions,
  results,
  setDocument,
  document,
  updateDocumentContent,
  checkGrammar,
  setErrorWordsWithColor,
  icon,
  setIcon,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 900px)");
  let errorWordsColor = [];
  let isCorrectWord = false;
  let count=0;
  // let allCorrectedWords = []; // Array to store all corrected words

  const getSuggestions = (categoryId, customColor) => {
    try {
      let filteredSuggestions = [];
  
      if (categoryId === "OTHERS") {
        filteredSuggestions = results?.filter((res) => {
          if (
            res.rule.category.id !== "GRAMMAR" &&
            res.rule.category.id !== "TYPOS" &&
            res.rule.category.id !== "MORFOLOGIK_RULE_EN_US" &&
            res.rule.category.id !== "CASING" &&
            res.rule.category.id !== "STYLE" &&
            res.rule.category.id !== "SEMANTICS" &&
            res.rule.category.id !== "TYPOGRAPHY" &&
            res.rule.category.id !== "SPELLING"
          )
            return res;
          return null;
        });
      } else {
        filteredSuggestions = results?.filter((res) => {
          if (res?.rule?.category?.id === categoryId) return res;
          return null;
        });
      }
      if (filteredSuggestions == null || filteredSuggestions?.length === 0)
        return [];
  
      return (
        filteredSuggestions?.map((res, idx) => {
          let message = res.message;
          let shortMessage = res.shortMessage
            ? res.shortMessage
            : res.rule.category.name;
          let correctWord = res?.replacements?.slice(0, 2);
  
          // if (correctWord && !allCorrectedWords.includes(correctWord[0])) {
          //   // Push only unique corrected words
          //   allCorrectedWords.push(correctWord[0]);
          // }
  
          let errorWord =
            getWordAt(document?.text, res?.offset, res?.length) ?? "";
          const precedingText = document?.text.slice(0, res?.offset);
          const precedingWordsArray = precedingText.split(" ");
          const precedingWords = precedingWordsArray.slice(-4).join(" ");
  
          errorWordsColor.push({
            errorWord,
            message,
            shortMessage,
            customColor,
            replacementLength: res?.replacements?.length,
            offset: res?.offset,
            replacementArr: res?.replacements,
            length: res?.length,
          });
          return (
            <div className="px-3 py-2" key={res.rule.category.id + idx}>
              <SuggestionCard
                // allCorrectedWords={allCorrectedWords}
                message={message}
                shortMessage={shortMessage}
                correctWord={correctWord}
                errorWord={errorWord}
                precedingWords={precedingWords}
                result={res}
                setDocument={setDocument}
                document={document}
                updateDocumentContent={updateDocumentContent}
                checkGrammer={checkGrammar}
                customColor={customColor}
              />
            </div>
          );
        }) ?? []
      );
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    if (errorWordsColor.length > 0) {
      setErrorWordsWithColor(errorWordsColor);
    }

    // eslint-disable-next-line
  }, [results, document?.text]);

  
  
  return isMobile ? (
    <div className="w-[100%] h-auto font-inter overflow-scroll scrollbar-none">
      
        <div
          className="ml-2 flex pb-2 justify-center items-center gap-2 px-2 py-0
						font-inter font-[600]	text-xl text-[#FF1D25] text-[1.2rem]"
        >
          <div className="">{results?.length ?? 0}</div>
          <div className=""> Grammer Corrections</div>
        </div>

        {/* <div className=" right-[0rem] text-xl pt-[4px] mr-9  text-[#000]  text-[1.2rem] font-[500] ">
          {countWords(document?.text)} words
        </div> */}
      
      

      <SuggestionCagegory
        categoryName="Grammar, Punctuation & Spelling"
        isCorrectWord={isCorrectWord}
        suggestionCards={[
          ...getSuggestions("GRAMMAR", CUSTOM_GREEN),
          ...getSuggestions("MORFOLOGIK_RULE_EN_US", CUSTOM_GREEN),
          ...getSuggestions("OTHERS", CUSTOM_GREEN),
        ]}
        bgColor="bg-customGreen"
      />
      <SuggestionCagegory
        categoryName="Style"
        isCorrectWord={isCorrectWord}
        suggestionCards={[
          ...getSuggestions("CASING", CUSTOM_YELLOW),
          ...getSuggestions("STYLE", CUSTOM_YELLOW),
        ]}
        bgColor="bg-customYellow"
      />
      <SuggestionCagegory
        categoryName="Semantics, Format & Typography"
        isCorrectWord={isCorrectWord}
        suggestionCards={[
          ...getSuggestions("SEMANTICS", CUSTOM_ORANGE),
          ...getSuggestions("SPELLING", CUSTOM_ORANGE),
          ...getSuggestions("TYPOGRAPHY", CUSTOM_ORANGE),
          ...getSuggestions("TYPOS", CUSTOM_ORANGE),
        ]}
        bgColor="bg-customOrange"
      />
      {/* <SuggestionCagegory
				categoryName="Others"
				suggestionCards={getSuggestions("OTHERS")}
			/> */}
    </div>
  ) : (
    // All Suggestions block
    <motion.div
      className=" w-[100%] h-0 border-l   relative border-[#DDDDDD]"
      initial={{ x: "100%" }} // Start off-screen to the left
      animate={{ x: "0%" }} // Slide in to its original position
      exit={{ x: "-100%" }} // Slide out back to the left
      transition={{ type: "tween", duration: 0.4 }} // Smooth animation
      style={{ zIndex: 999 }}
    >
      <div className="flex justify-between h-14  gap-32 items-center border-l-2 ">
        <div
          className="pt-2 px-3 flex
          text-[black]
          text-[18px]
          font-medium 
           font-sans "
        >
          All Corrections
        </div>

        <div className="ml-2 px-2 py-0" style={{ cursor: "pointer" }}>
          <Img
            className=""
            style={{
              height: "24px",
              width: "24px",
              marginTop: "2px",
              marginLeft: "10%",
            }}
            src={crossIcon}
            onClick={() => {
              setGrammarCorrection(false);
            }}
          />
        </div>
      </div>

      
      <div className=" absolute top-[57px] w-[100%] h-[91vh] z-30   bg-white  font-inter overflow-scroll scrollbar-none border-b-2  border-l-2  border-[#DDDDDD]  ">
        {/* <hr /> */}
        {results == 0 ?
         <div 
         className="flex flex-col justify-center items-center h-[85%] space-y-3 p-6 transition-all duration-500 ease-in-out"
       >
         <div className="animate-bounce-slow">
           <Img 
             src={noCorrectionIcon} 
             className="h-[60px] w-[60px] opacity-70 transform transition-all duration-300 hover:scale-110 hover:rotate-6"
           />
         </div>
         <div 
           className="
             text-xl 
             text-gray-500 
             font-semibold 
             tracking-wide 
             opacity-80 
             transition-all 
             duration-700 
             ease-in-out 
             hover:text-blue-500 
             hover:opacity-100
             text-center
             px-4
           "
         >
           No Grammatical Corrections Needed
         </div>
         <div 
           className="
             text-sm 
             text-gray-400 
             italic 
             text-center 
             max-w-md 
             opacity-60 
             transition-opacity 
             duration-500 
             hover:opacity-100
           "
         >
           Your writing looks clean and precise. Keep up the great work!
         </div>
       </div>
        : <div>
          <SuggestionCagegory
          categoryName="Grammar, Punctuation & Spelling"
          isCorrectWord={isCorrectWord}
          suggestionCards={[
            ...getSuggestions("GRAMMAR", CUSTOM_GREEN),
            ...getSuggestions("MORFOLOGIK_RULE_EN_US", CUSTOM_GREEN),
            ...getSuggestions("OTHERS", CUSTOM_GREEN),
          ]}
          bgColor="bg-customGreen"
        />
        <SuggestionCagegory
          categoryName="Style"
          isCorrectWord={isCorrectWord}
          suggestionCards={[
            ...getSuggestions("CASING", CUSTOM_YELLOW),
            ...getSuggestions("STYLE", CUSTOM_YELLOW),
          ]}
          bgColor="bg-customYellow"
        />
        <SuggestionCagegory
          categoryName="Semantics, Format & Typography"
          isCorrectWord={isCorrectWord}
          suggestionCards={[
            ...getSuggestions("SEMANTICS", CUSTOM_ORANGE),
            ...getSuggestions("SPELLING", CUSTOM_ORANGE),
            ...getSuggestions("TYPOGRAPHY", CUSTOM_ORANGE),
            ...getSuggestions("TYPOS", CUSTOM_ORANGE),
          ]}
          bgColor="bg-customOrange"
        />
        {/* <SuggestionCagegory
      categoryName="Others"
      suggestionCards={getSuggestions("OTHERS")}
    /> */}</div>}
        
      </div>
    </motion.div>
  );
};

export default Suggestions;
