import React, { useState, useEffect } from "react";
import arrow from "../../assets/Suggestions/-_.png";
import greenDot from "../../assets/Suggestions/green-dot.svg";
import Group from "../../assets/Editor/Group.png";
import { replaceWord } from "../../libs/utility";
import "./SuggestionCard.css";
import CircularProgress from "@mui/material/CircularProgress";

const SuggestionCard = ({
  result,
  message,
  shortMessage,
  correctWord: initialCorrectWord,
  precedingWords,
  errorWord,
  document,
  selectedErrorIndex,
  setDocument,
  updateDocumentContent,
  checkGrammer,
  setSelectedErrorIndex
}) => {
  const [correctWord, setCorrectWord] = useState(initialCorrectWord);
  const [expandedOuterIndex, setExpandedOuterIndex] = useState(null); // Track outer expansion
  const [expandedInnerIndex, setExpandedInnerIndex] = useState(null); // Track inner expansion
  const [loadingIndex, setLoadingIndex] = useState(null); // Track loading state for buttons

  // useEffect(() => {
  //   if (selectedErrorIndex !== null && correctWord && correctWord.length > 0) {
  //     setExpandedOuterIndex(0); // Open the first suggestion
  //   }
  // }, [selectedErrorIndex, correctWord]);

  const fixWord = async (data, outerIndex) => {

    if (!result || !correctWord || !document) return;

    setLoadingIndex(outerIndex); // Set loading state for the clicked button

    const text = document.text;
    let updatedWordValue = " ";

    correctWord?.forEach((newWord) => {
      if (newWord?.value === data) {
        const finalword = newWord?.value;
        updatedWordValue = finalword;
      }
    });

    if (updatedWordValue !== " ") {
      const correctString = await replaceWord(
        text,
        updatedWordValue,
        result?.offset,
        result?.length
      );

      setDocument({ ...document, text: correctString });
      await saveAndCheck(correctString);
    }

    setLoadingIndex(null); // Remove loading state after processing
  };

  const saveAndCheck = async (correctString) => {
    if (updateDocumentContent != null) await updateDocumentContent(correctString);
    if (checkGrammer != null) await checkGrammer();
  };

  const handleDismiss = (valueToRemove) => {
    const updatedWords = correctWord.filter(
      (item) => item.value !== valueToRemove
    );
    setCorrectWord(updatedWords);
  };

  function truncateText(input) {
    if (input.length > 8) {
      return input.slice(0, 5) + "...";
    }
    return input;
  }


  const stripHtml = (html) => {
    // Replace &nbsp; with an empty string first
    html = html.replace(/&nbsp;/g, '');

    // Remove all HTML tags
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const toggleOuterExpand = (index) => {
    setExpandedOuterIndex(expandedOuterIndex === index ? null : index);
    setExpandedInnerIndex(null); // Close inner suggestion when outer is toggled
  };

  const toggleInnerExpand = (index) => {
    setExpandedInnerIndex(expandedInnerIndex === index ? null : index);
  };
  const truncateErrorText = (text, maxLength = 30) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };


  return (
    <>

      {(correctWord || correctWord === "") && (
        <div className="suggestion-card font-inter shadow-md">
          <div>
            {correctWord.map((obj, outerIndex) => {
            return(
              <div
                key={outerIndex}
                className="mb-2 border border-gray-300 rounded-lg cursor-pointer"
              >
                {/* Outer Suggestion Header */}
                <div
                  className="p-3 flex justify-between items-center bg-gray-100 "
                  onClick={() => toggleOuterExpand(outerIndex)
                    
                  }
                >
                  <div>
                    <span
                      className={`error-word-suggestionCard font-semibold ${result.rule.category?.id === "TYPOS"
                          ? "text-orange-500"
                          : result.rule.category?.id === "SPELLING"
                            ? "text-purple-500"
                            : result.rule.category?.id === "TYPOGRAPHY"
                              ? "text-blue-600"
                              : result.rule.category?.id === "CASING"
                                ? "text-green-500"
                                : result.rule.category?.id === "SEMANTICS"
                                  ? "text-pink-400"
                                  : result.rule.category?.id === "STYLE"
                                    ? "text-yellow-800"
                                    : result.rule.category?.id === "MORFOLOGIK_RULE_EN_US"
                                      ? "text-teal-500"
                                      : result.rule.category?.id === "PUNCTUATION"
                                        ? "text-yellow-400"
                                        : result.rule.category?.id === "MISC"
                                          ? "text-violet-500"
                                          : result.rule.category?.id === "GRAMMAR"
                                            ? "text-red-500"
                                            : result.rule.category?.id === "CONFUSED_WORDS"
                                              ? "text-orange-400"
                                              : "text-gray-500"
                          }`}
                      >
                        {truncateText(errorWord)}
                      </span>
                      {" "}
                      - {shortMessage}
                    </div>

                    <img
                      src={arrow}
                      alt="Toggle"
                      className={`arrow-icon w-4 h-4 transform ${expandedOuterIndex === outerIndex ? "rotate-180" : "rotate-0"
                        }`}
                    />
                  </div>

                {/* Outer Suggestion Content */}
                {expandedOuterIndex === outerIndex && (
                  <div
                    className={`expandable-content ${expandedOuterIndex === outerIndex ? "expanded" : ""
                      } p-[6%] overflow-clip mr-2 cursor-auto `}
                  >
                    <div>
                      ....{  stripHtml(precedingWords)}{" "}
                      <span className="text-[14px] inline text-[#3A3AF4] font-[600]">
                        {obj.value}
                      </span>
                    </div>

                    <div className="mt-2 flex items-center gap-4">
                      <button
                        className="accept-button bg-[#3A3AF4] text-white text-[12px] shadow-sm flex items-center justify-center"
                        style={{
                          width: "60px",
                          height: "22px",
                          position: "relative",
                        }}
                        onClick={() => fixWord(obj.value, outerIndex)}
                        disabled={loadingIndex === outerIndex}
                      >
                        {loadingIndex === outerIndex ? (
                          <CircularProgress
                            size={14}
                            color="inherit"
                            style={{
                              position: "absolute",
                            }}
                          />
                        ) : (
                          "Accept"
                        )}
                      </button>
                      <button
                        className="dismiss-button text-[#000000] text-[12px] bg-[#F6F3F3] shadow-sm"
                        style={{
                          width: "60px",
                          height: "22px",
                          boxShadow:
                            "0 0 0px 0px #000000, 0 0 4px 4px #f3f4f6",
                        }}
                        onClick={() => handleDismiss(obj.value)}
                      >
                        Dismiss
                      </button>
                      <img src={Group} alt="" className="h-[16px] w-[16px] cursor-pointer" />
                    </div>
                  </div>
                )}
              </div>
            );})}
          </div>
        </div>
      )}
    </>
  );
};

export default SuggestionCard;
