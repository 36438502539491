import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChatBot from "./chatBot/ChatBot";
import GrammerEditor from "./GrammarEditor/GrammarEditorNew";
import "./AIWritingAssistant.css";
import Suggestions from "./Suggestions Pane/Suggestions";
import PlagReport from "./Plagiarism/PlagReport";
import { Img } from "@chakra-ui/react";
import menuIcon from "../assets/Editor/menuIcon.png";
import Leftpane from "./Leftpane";
import HashLoader from "react-spinners/HashLoader";
import { motion } from "framer-motion";
import PromptsPopup from "../components/chatBot/PromptsPopup";


const drawerWidth = 394;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative",
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderBottom: "2px solid #DDDDDD",
  padding: theme.spacing(0, 1),
  marginTop: "-6.5px",
  borderRadius: "0 !important",
  backgroundColor: "white !important", // Setting background to white with !important
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const AIWritingAssistant = ({
  loading,
  setLoading,
  setMenuOpen,
  menuOpen,
  toolbarRef,
  document,
  setDocument,
  checkGrammar,
  updateDocumentContent,
  updatedocumentTitle,
  errorWordsWithColor,
  setErrorWordsWithColor,
  setIsDocUpdateAPI,
  isDocUpdateAPI,
  results,
  report,
  checkPlag,
  plagResult,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [grammarCorrection, setGrammarCorrection] = React.useState(false);
  const [view, setView] = React.useState(true);
  const [plagDrawerOpen, setPlagDrawerOpen] = useState(false);
  const [isPromptsPopupOpen, setPromptsPopupOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const [selectedErrorIndex, setSelectedErrorIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
    const [selectedErrorKey, setSelectedErrorKey] = useState(null);
  
  // const [doc, setDoc] = useState({ text: "" });
  const handleAIDrawerOpen = () => {
    setOpen(true);
    setGrammarCorrection(false);
    // setPlagDrawerOpen(false)
    setPlagDrawerOpen(false);
    setIsOpen(false)
    setSelectedErrorKey(null)
  };

  const handleDrawerClose = () => {
    // window.location.reload(false);
    setOpen(false);
  };

  const closeSuggestions = () => {
    setGrammarCorrection(false);
  };

  const handlePlagDrawerOpen = () => {
    setPlagDrawerOpen(true);
    setOpen(false);
    setGrammarCorrection(false);
    setIsOpen(false)
    setSelectedErrorKey(null)
 
  };
  const handleSuggestionDrawerOpen = () => {
    setGrammarCorrection(!grammarCorrection);
    setMenuOpen(false)
    setOpen(false);
    setPlagDrawerOpen(false);
    setIsOpen(false)
    setSelectedErrorKey(null)
  };

  return (
    <>
      <div className="w-full relative "
       onClick={() => {
        setMenuOpen(false);
        setGrammarCorrection(false);
        setPlagDrawerOpen(false);
        setOpen(false);
        setIsOpen(false)
        setSelectedErrorKey(null)
        
        
      }}
      >       
        <Box
          sx={{
            display: "flex",
            gap: "2%",
            width: "100%",
            justifyContent: "space-between",
            borderBottom: "2px solid", // Specify the style and color
            borderBottomColor: "#DDDDDD", // Add your preferred color here
          }}
        >
          <div
            className="absolute top-[0px]"
            // className="fixed"
            style={{
              height: "40px",
              width: "4%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative", // Set relative position for positioning child elements
            }}
            onClick={(e) => e.stopPropagation()}
          >
            
             <Img
                className="cursor-pointer"
                style={{
                  height: "24px",
                  width: "24px",
                  position: "absolute",
                  top: 0,
              marginTop: "30%",

                }}
                src={menuIcon}
                onClick={() => setMenuOpen(!menuOpen)}
              />

            {menuOpen && (
              <motion.div
                style={{
                  position: "absolute",
                  // top: "16px", // Adjust based on your layout needs
                  left: 0,
                  zIndex: 10, // Ensure it overlaps other content when open
                }}
              >
                <Leftpane
                  toolbarRef={toolbarRef}
                  currentDocument={document}
                  setMenuOpen={setMenuOpen}
                  menuOpen={menuOpen}
                />
              </motion.div>
            )}
          </div>

          <div className="flex   justify-between" onClick={(e) => e.stopPropagation()}>
            <div className=" flex justify-between py-2 items-center">
              {!grammarCorrection ? (
                <IconButton
                  className=" GrammarCorrections fixed mt-0 shadow-sm"
                  // onClick={() => {
                  //   setGrammarCorrection(!grammarCorrection);
                  //   setMenuOpen(false);
                  // }}
                  onClick={handleSuggestionDrawerOpen}
                  style={{                    
                    height: "40px",
                    background: "#E7F3FF",
                    borderRadius: "0px",
                    // width: "16%",
                    marginRight: "10px",
                    // padding: "18px",
                    // color: "white"
                  }}
                  // color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  // onClick={handleDrawerOpen}
                  // sx={{ ...(open && { display: "none" }) }}
                  disableFocusRipple={false}
                >
                  <span
                    style={{
                      marginRight: "2%",
                      color: "#16A34A",
                      fontWeight: 700,
                    }}
                  >
                    {loading ? (
                      <HashLoader color="#16A34A" size={20} />
                    ) : (
                      results?.length ?? (
                        <HashLoader color="#16A34A" size={20} />
                      )
                    )}
                  </span>
                  Grammar <span className="ml-1">Corrections</span>
                </IconButton>
              ) : (
                <IconButton
                  className=" ViewPlagiarism shadow-sm mt-0 "
                  style={{
                    fontSize: "",
                    height: "40px",
                    background: "#E7F3FF",
                    borderRadius: "0px",
                    // width: "16%",
                    marginRight: "20px",
                  }}
                  // color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handlePlagDrawerOpen}
                  // sx={{ ...(open && { display: "none" }) }}
                  // onClick={() => {
                  //   setPlagDrawerOpen(true);
                  // }}
                  disableFocusRipple={false}
                >
                  Check Plagiarism
                </IconButton>
              )}

              <IconButton
                className={` yourAssistant shadow-sm mt-0 px-8`}
                style={{
                  // alignItems: "center",
                  height: "40px",
                  background: "#3b82f6",
                  borderRadius: "0px",
                  marginRight: "20px",

                  // width: "50%"
                }}
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleAIDrawerOpen}
                sx={{ ...(open && { display: "none" }) }}
                disableFocusRipple={false}
              >
                <span className="">
                  Your <span>AI</span> <span>Assistant</span>
                </span>
              </IconButton>
            </div>
            <div className=" " onClick={(e) => e.stopPropagation()}>


              {open == true ? (
                <div
                  className="w-[100%]   font-inter 
            flex"
                >

                  <ChatBot setOpen={setOpen} setInputText={setInputText} inputText={inputText} setPromptsPopupOpen={setPromptsPopupOpen} />
                </div>
              ) : null}

              {grammarCorrection == true ? (
                <div
                  className="w-[100%]   font-inter 
            flex"
                >
                  <Suggestions
                    setGrammarCorrection={setGrammarCorrection}
                    grammarCorrection={grammarCorrection}
                    setView={setView}
                    closeSuggestions={closeSuggestions}
                    view={view}
                    results={results}
                    setDocument={setDocument}
                    document={document}
                    updateDocumentContent={updateDocumentContent}
                    checkGrammar={checkGrammar}
                    setErrorWordsWithColor={setErrorWordsWithColor}
                    setSelectedErrorIndex={setSelectedErrorIndex}
                    selectedErrorIndex={selectedErrorIndex}
                  />
                </div>
              ) : null}

              {/* Plagiarism Report Drawer */}
              {plagDrawerOpen == true ? (
                <div
                  className="w-[100%]   font-inter 
            flex"
                >
                  <PlagReport
                    setPlagDrawerOpen={setPlagDrawerOpen}
                    report={plagResult?.results ?? null}
                    checkPlag={checkPlag}
                    plagResult={plagResult}
                  />
                </div>
              ) : null}
            </div>
            
          </div>
          {/* 
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
          height: "100%",
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon  sx={{ fontSize: 40 }} />
            ) : (
              <ChevronRightIcon sx={{ fontSize: 40 }} />
            )}
          </IconButton>
        </DrawerHeader>

        <ChatBot />
      </Drawer>

      <div className="d-flex justify-center items-center">
        <IconButton
          style={{
            alignItems: "center",
            transform: "rotate(270deg)",
            height: "25px",
            background: "#3b82f6",
            borderRadius: "10px",
            padding: "18px",
            color: "white"
          }}
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          sx={{ ...(open && { display: "none" }) }}
          disableFocusRipple={false}
        >
          AI Writer
        </IconButton>
      </div>  */}

          {/* <div style={{ display: "flex", gap: "7%", marginRight: "8%" }}>
          <IconButton
            className=" GrammarCorrections "
            onClick={() => { setGrammarCorrection(!grammarCorrection); setMenuOpen(false) }}
            style={{
              fontSize: "",
              height: "40px",
              background: "#E7F3FF",
              borderRadius: "0px",
              width: "65%",
              marginLeft: "-10%"

              // padding: "18px",
              // color: "white"
            }}
            // color="inherit"
            aria-label="open drawer"
            edge="end"
            // onClick={handleDrawerOpen}
            // sx={{ ...(open && { display: "none" }) }}
            disableFocusRipple={false}
          >
            <span style={{ marginRight: "2%", color: "#16A34A", fontWeight: 700 }}>{results?.length ?? 0}</span> Grammar Corrections
          </IconButton>

          <IconButton
            className="yourAssistant"
            style={{
              // alignItems: "center",
              height: "40px",
              background: "#3b82f6",
              borderRadius: "1px",
              width: "35% !important"
            }}
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
            disableFocusRipple={false}
          >
            Your AI Assistant
          </IconButton>

          {grammarCorrection && <div className=" h-[100vh] font-inter overflow-scroll scrollbar-thin flex">
            <Suggestions
              setGrammarCorrection={setGrammarCorrection}
              grammarCorrection={grammarCorrection}
              setView={setView}
              closeSuggestions={closeSuggestions}
              view={view}
              results={results}
              setDocument={setDocument}
              document={document}
              updateDocumentContent={updateDocumentContent}
              checkGrammar={checkGrammar}
              setErrorWordsWithColor={setErrorWordsWithColor}
            />

            <PlagReport
              report={plagResult?.results ?? null}
              checkPlag={checkPlag}
              plagResult={plagResult}
            />

          </div>}
        </div> */}
        </Box>

        <Box open={open} style={{ height: "100%", width: "70%" }}>
          <GrammerEditor
          isOpen={isOpen}
          setIsOpen={setIsOpen}
            setLoading={setLoading}
            toolbarRef={toolbarRef}
            document={document}
            setDocument={setDocument}
            checkGrammar={checkGrammar}
            updateDocumentContent={updateDocumentContent}
            updatedocumentTitle={updatedocumentTitle}
            errorWordsWithColor={errorWordsWithColor}
            results={results}
            setErrorWordsWithColor={setErrorWordsWithColor}
            setIsDocUpdateAPI={setIsDocUpdateAPI}
            isDocUpdateAPI={isDocUpdateAPI}
            open={open}
            setSelectedErrorIndex={setSelectedErrorIndex}
            selectedErrorKey={selectedErrorKey}
            setSelectedErrorKey={setSelectedErrorKey}
            setOpen={setOpen} //for ai drawer
            setPlagDrawerOpen={setPlagDrawerOpen} //palg drawer
            setGrammarCorrection={setGrammarCorrection} //suggestion drawe
          />
        </Box>
      </div>
      <div>
      {isPromptsPopupOpen && <PromptsPopup setInputText={setInputText} onClose={() => setPromptsPopupOpen(false)} />}
      </div>
    </>
  );
};

export default AIWritingAssistant;
